import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { schemaGeneralData } from '~/validation/validators/appointment/CreateAppointmentValidator';

import { Input, Select } from '../UI';
import { Container, Form } from './styles/StyledGeneralData';
import { translator } from '../i18n';
import {
  iListConsultant,
  iListProfessional,
  iListSpeciality,
  iRegisterAppointment,
} from './interface';

import { ConnectComponent } from './mapper/consultant';

import { Navigator } from './Navigator';
import { makeReduxGetAllAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllAppointmentPriorities } from '~/main/factories/usecases/appointmentPriorities/GetAllAppointmentPriorities';
import {
  CheckBoxInput,
  FooterContent,
  TextNormalBlackFour,
} from '../cardWaitingRoomDetails/styles';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { Label } from '../UI/select/styles';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';

import SearchSelect from '../UI/searchSelect';

import { selectStyles } from '../modalRequestConsultation/styles/StyledModalRequestConsultation';

export interface externalProps {
  next: (data: iRegisterAppointment) => any;
  state?: iRegisterAppointment;
}

export interface ownProps {
  specialty: iListSpeciality[];
  professionals: iListProfessional[];
  consultant: iListConsultant[];
  filterProfessional: (id: number | undefined) => iListProfessional[];
}

const GeneralData: React.FC<ownProps & externalProps> = ({
  next,
  state,
  specialty,
  professionals,
  consultant,
  filterProfessional,
}): JSX.Element => {
  const { errors, handleSubmit, register, setValue, getValues, watch } =
    useForm({
      mode: 'onChange',
      shouldFocusError: true,
      resolver: zodResolver(schemaGeneralData),
      defaultValues: {
        ...state,
      },
    });

  const [transcription, setTranscription] = useState(
    getValues().allowTranscription ? '1' : '0',
  );

  const { data: appointmentTypes } = useSelector(
    (store: iStore) => store.appointmentType,
  );

  const { data: appointmentPriorities } = useSelector(
    (store: iStore) => store.appointmentPriorities,
  );

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const onSubmit = handleSubmit(data => {
    next(data);
  });

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.name} [${value.city.name}]`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const generateConsultantSelectOptions = useCallback(() => {
    return _.sortBy(
      consultant?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.firstName} ${value.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [consultant]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option.value === watch('healthUnit'),
    );
  }, [generateHealthUnitSelectOptions, watch]);

  const consultantMultiselectSelected = useMemo(() => {
    return generateConsultantSelectOptions().find(
      option => option.value === watch('consultant'),
    );
  }, [generateConsultantSelectOptions, watch]);

  useEffect(() => {
    register('allowTranscription');
    register('healthUnit');

    makeReduxGetAllAppointmentType().list({
      client: 'SAUDEMEET',
    });

    makeReduxGetAllAppointmentPriorities().list({
      query: {
        limit: 9999,
      },
    });

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });
  }, [register]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <SearchSelect
          id="appointmentPatient"
          name="consultant"
          label={translator('Paciente')}
          placeholder={translator('Selecione um paciente')}
          onChange={e => {
            setValue('consultant', Number(e?.value));
          }}
          error={Boolean(errors.consultant)}
          message={
            errors?.consultant?.message
              ? translator(errors?.consultant?.message)
              : ''
          }
          autoFocus
          required
          options={generateConsultantSelectOptions()}
          register={() => register('consultant')}
          value={consultantMultiselectSelected}
        />

        <Select
          id="select_appointmentType"
          name="type"
          defaultValue={state?.type}
          label={translator('Tipo de serviço')}
          onChange={e => setValue('type', Number(e.target.value))}
          register={() => register('type')}
          error={Boolean(errors.type)}
          message={errors.type?.message && 'Selecione um tipo de atendimento'}
          required
        >
          <option id="option_0" value={-1}>
            {translator('Selecione um tipo')}
          </option>

          {appointmentTypes?.records?.map((item, index) => (
            <option id={`option_${index}`} value={item.id}>
              {translator(item.msgKey)}
            </option>
          ))}
        </Select>

        <Select
          id="select_modality"
          name="modality"
          defaultValue={state?.modality}
          label={translator('Modalidade')}
          onChange={e => setValue('modality', e.target.value)}
          register={() => register('modality')}
          error={Boolean(errors.modality)}
          message={errors.modality?.message && 'Selecione uma modalidade'}
          required
        >
          <option id="modality_option_1" value="REMOTE">
            {translator('Remoto')}
          </option>

          <option id="modality_option_2" value="INPERSON">
            {translator('Presencial')}
          </option>
        </Select>

        <Select
          id="select_appointmentPriorities"
          name="priority"
          defaultValue={state?.priority}
          label={`${translator('Prioridade')}`}
          onChange={e => setValue('priority', Number(e.target.value))}
          register={() => register('priority')}
          error={Boolean(errors.priority)}
          message={errors.priority?.message && 'Selecione uma prioridade'}
        >
          <option id="option_0" value={-1}>
            {translator('Selecione uma prioridade')}
          </option>

          {appointmentPriorities?.records?.map((item, index) => (
            <option id={`option_${index}`} value={item.id}>
              {item.descr}
            </option>
          ))}
        </Select>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Label style={{ marginBottom: '6px' }}>
            {translator('Unidade de saúde')}
          </Label>
          <CreatableSelect
            className="select"
            components={{
              Control,
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option,
            }}
            controlShouldRenderValue
            placeholder="Selecione a unidade"
            formatCreateLabel={(label: string) => `Buscar por ${label}`}
            options={generateHealthUnitSelectOptions()}
            value={healthUnitMultiselectSelected}
            onChange={e => {
              setValue('healthUnit', Number(e?.value));
            }}
            styles={selectStyles()}
            isValidNewOption={() => false}
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
          />
        </div>

        <div
          style={{
            gridArea: 'obs',
          }}
        >
          <Input
            label={`${translator('Observação')}`}
            id="obs"
            defaultValue={getValues()?.obs}
            onChange={e => setValue('obs', e.target.value)}
            register={() => register('obs')}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '12px',
            gridArea: 'transcription',
          }}
        >
          <TextNormalBlackFour>
            {translator('Gerar documento com a transcrição do atendimento:')}
          </TextNormalBlackFour>

          <div
            style={{
              display: 'flex',
              gap: '24px',
            }}
          >
            <FooterContent>
              <CheckBoxInput
                type="radio"
                value="1"
                checked={transcription === '1'}
                onChange={() => {
                  setTranscription('1');
                  setValue('allowTranscription', true);
                }}
              />
              <TextNormalBlackFour>{translator('Sim')}</TextNormalBlackFour>
            </FooterContent>

            <FooterContent>
              <CheckBoxInput
                type="radio"
                value="0"
                checked={transcription === '0'}
                onChange={() => {
                  setTranscription('0');
                  setValue('allowTranscription', false);
                }}
              />
              <TextNormalBlackFour>{translator('Não')}</TextNormalBlackFour>
            </FooterContent>
          </div>
        </div>

        <Navigator />
      </Form>
    </Container>
  );
};

export default ConnectComponent(GeneralData);
