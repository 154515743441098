import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  ContainerModal,
  SelectLabel,
  TextAreaForm,
  Container,
  Header,
  Titles,
  Main,
  SectionLeft,
  SectionRight,
  InlineForms,
  Footer,
  Form,
  selectStyles,
} from './styles/StyledEmergencyConsultation';
import {
  Button,
  IconButton,
  Input,
  Typography,
} from '@wisecare-tech/design-system-web';
import SearchSelect from '../UI/searchSelect';
import InputMask from 'react-input-mask';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import { zodResolver } from '@hookform/resolvers/zod';
import { createEmergencyConsultationSchema } from '~/validation/validators/emergencyConsultation/CreateEmergencyConsultation';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { useSelector } from 'react-redux';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import _ from 'lodash';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';

interface iPropsModalEmergencyConsultation {
  message: iMessage;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

type FormData = {
  consultantName: string;
  sex: string;
  birthDate: string;
  docType: string;
  docNumber: string;
  professionalName?: string;
  state: string;
  city: string;
  healthUnit: string;
  id?: number;
};

export const EmergencyConsultation: React.FC<
  iPropsModalEmergencyConsultation
> = ({ message }) => {
  const [cities, setCities] = useState<GetCitiesByUf.Model>([]);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { results: professional } = useSelector(
    (store: iStore) => store.professional,
  );

  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const specialty = useSelector((state: iStore) => state.auth.info);
  const { results: professionals } = useSelector(
    (state: iStore) => state.professional,
  );

  const professionalId = useMemo(() => {
    const idProfessional = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.id;

    return idProfessional;
  }, [orgId, specialty?.professionals]);

  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  const specialtyId = useMemo(() => {
    const idSpecialty = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.professions.specialties[0].id;

    return idSpecialty;
  }, [orgId, specialty?.professionals]);

  const { active, actionCancel } = message;

  const msgName = MessageOptions.emergencyConsultation;

  const {
    errors,
    setValue,
    register,
    watch,
    handleSubmit,
    control,
    formState,
  } = useForm<FormData>({
    resolver: zodResolver(createEmergencyConsultationSchema) as any,
    mode: 'all',
    defaultValues: {
      birthDate: '',
      city: '',
      consultantName: '',
      docNumber: '',
      docType: '',
      healthUnit: '',
      professionalName: '',
      sex: '',
      state: 'PB',
      id: undefined,
    },
  });

  const resetForm = () => {
    setValue('birthDate', '');
    setValue('city', '');
    setValue('consultantName', '');
    setValue('docNumber', '');
    setValue('docType', '');
    setValue('healthUnit', '');
    setValue('professionalName', '');
    setValue('id', undefined);
  };

  const onSubmit: SubmitHandler<FormData> = data => {
    makeRemoteCreateAppointment()
      .create({
        org: orgId,
        orgUnit: orgUnitId,
        professional: professionalId,
        specialty: specialtyId,
        healthUnit: Number(data?.healthUnit),
        organizer: id,
        date: String(new Date()),
        professionals: [
          {
            id: Number(data?.id) ? Number(data?.id) : undefined,
            name: data?.professionalName ? data?.professionalName : undefined,
          },
        ],
        info: {
          birthDate: data?.birthDate,
          name: data?.consultantName,
          regType: data?.docType,
          regValue: data?.docNumber,
          sex: data?.sex,
        },
        service: 'INSTANTANEOUS',
      })
      .then(res => {
        resetForm();
        makeReduxActiveMessage().active({
          active: MessageOptions.instantConsultationSuccess,
          link: res.links.otherProfessionals?.[0].link,
          data: {
            professional: id,
            appointmentId: res.id,
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao criar interconsulta!',
          type: 'danger',
        });
      });
  };

  const createOption = (label: string, value?: string) => ({
    label,
    value: value ?? '',
  });

  const options = useMemo(() => {
    return professional?.map(value =>
      createOption(
        `${value?.user?.firstName} ${value?.user?.lastName}`,
        String(value?.professional?.id),
      ),
    );
  }, [professional]);

  const handleCreate = useCallback(
    (inputValue: string) => {
      setTimeout(() => {
        const newOption = createOption(inputValue);
        setValue('professionalName', newOption.label);
      }, 1000);
    },
    [options],
  );

  const generateCitySelectOptions = () => {
    return _.sortBy(
      cities?.flatMap(value => {
        return [
          {
            label: value.nome,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const generateSexSelectOptions = () => {
    return [
      {
        value: 'M',
        label: 'Masculino',
      },
      {
        value: 'F',
        label: 'Feminino',
      },
    ];
  };

  const generateDocTypeSelectOptions = () => {
    return _.sortBy(
      regionalDocs?.flatMap(value => {
        return [
          {
            value: value?.id,
            label: value?.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const generateStateSelectOptions = () => {
    return [
      {
        label: 'PB',
        value: 'PB',
      },
    ];
  };

  const generateHealthUnitSelectOptions = useCallback(() => {
    if (!watch('city')) return [];

    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            label: `${value.cnes} - ${value.name} [${value.city.name}]`,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option.value === Number(watch('healthUnit')),
    );
  }, [generateHealthUnitSelectOptions, watch]);

  const stateMultiselectSelected = useMemo(() => {
    return generateStateSelectOptions().find(
      option => option?.value === watch('state'),
    );
  }, [generateStateSelectOptions]);

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });

    makeReduxGetAllUsers().getAll({
      pageSize: 9999,
    });

    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: 'BRA',
      })
      .then((res: { records: React.SetStateAction<RegionalDoc[]> }) => {
        setRegionalDocs(res.records);
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  }, []);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: watch('city'),
    });
  }, [watch('city')]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <Titles>
                <Typography variant="t1_18semibold">
                  Interconsulta emergencial
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  Preencha os dados abaixo para criar a interconsulta. É
                  possível editá-los após a criação.
                </Typography>
              </Titles>
              <IconButton
                icon="close"
                variant="primary"
                onClick={() => {
                  resetForm();
                  actionCancel?.();
                }}
              />
            </Header>
            <Main>
              <SectionLeft>
                <Typography variant="t4_16medium" style={{ marginBottom: 16 }}>
                  Informações do paciente
                </Typography>

                <TextAreaForm>
                  <SelectLabel required>Nome completo</SelectLabel>
                  <Controller
                    name="consultantName"
                    control={control}
                    render={({ value, onChange }) => (
                      <Input
                        height="40px"
                        {...value}
                        required
                        onChange={onChange}
                        helperText={errors?.consultantName?.message}
                        status={errors?.consultantName ? 'error' : 'default'}
                        placeholder="Digite o nome do paciente"
                      />
                    )}
                  />
                </TextAreaForm>

                <Form>
                  <InlineForms>
                    <Controller
                      name="sex"
                      control={control}
                      render={({ value, onChange }) => (
                        <SearchSelect
                          {...value}
                          className="select"
                          components={{
                            Control,
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                            Option,
                          }}
                          options={generateSexSelectOptions()}
                          onChange={(e: { value: string }) => {
                            onChange(e.value);
                          }}
                          controlShouldRenderValue
                          isValidNewOption={() => false}
                          styles={selectStyles()}
                          message={errors?.sex?.message}
                          error={Boolean(errors?.sex)}
                          noOptionsMessage={() => 'Nenhum resultado encontrado'}
                          placeholder="Escolha"
                          label="Sexo"
                          required
                        />
                      )}
                    />

                    <TextAreaForm>
                      <SelectLabel required>Data do nascimento</SelectLabel>

                      <Controller
                        control={control}
                        name="birthDate"
                        render={({ value, onChange }) => (
                          <InputMask
                            {...value}
                            mask="99/99/9999"
                            placeholder="00/00/0000"
                            name="birthDate"
                            onChange={onChange}
                          >
                            <Input
                              height="40px"
                              required
                              helperText={errors?.birthDate?.message}
                              status={errors?.birthDate ? 'error' : 'default'}
                            />
                          </InputMask>
                        )}
                      />
                    </TextAreaForm>
                  </InlineForms>
                  <InlineForms style={{ alignItems: 'baseline' }}>
                    <Controller
                      control={control}
                      name="docType"
                      render={({ value, onChange }) => (
                        <SearchSelect
                          {...value}
                          className="select"
                          components={{
                            Control,
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                            Option,
                          }}
                          options={generateDocTypeSelectOptions()}
                          onChange={(e: { value: string; label: string }) => {
                            if (!e) return;
                            onChange(e.label);
                          }}
                          controlShouldRenderValue
                          required
                          error={Boolean(errors?.docType)}
                          message={errors?.docType?.message}
                          label="Tipo de documento"
                          placeholder="Escolha um documento"
                          styles={selectStyles()}
                          isValidNewOption={() => false}
                          noOptionsMessage={() => 'Nenhum resultado encontrado'}
                        />
                      )}
                    />
                    <TextAreaForm>
                      <div style={{ marginBottom: '14px' }} />
                      <Controller
                        name="docNumber"
                        control={control}
                        render={({ value, onChange }) => (
                          <Input
                            height="40px"
                            {...value}
                            required
                            onChange={onChange}
                            helperText={errors?.docNumber?.message}
                            status={errors?.docNumber ? 'error' : 'default'}
                          />
                        )}
                      />
                    </TextAreaForm>
                  </InlineForms>
                </Form>
              </SectionLeft>

              <SectionRight>
                <Typography variant="t4_16medium" style={{ marginBottom: 16 }}>
                  Informações do atendimento
                </Typography>

                <Form>
                  <Controller
                    control={control}
                    name="professionalName"
                    render={({ value, onChange }) => (
                      <SearchSelect
                        {...value}
                        className="select"
                        components={{
                          Control,
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                          Option,
                        }}
                        options={options}
                        handleCreate={handleCreate}
                        formatCreateLabel={(label: string) => {
                          if (!label?.length) return '';
                          return `Profissional externo: ${label}`;
                        }}
                        onChange={(e: {
                          value: string;
                          label: string;
                          __isNew__: boolean;
                        }) => {
                          if (e?.__isNew__) {
                            register('id');
                            setValue('id', undefined);
                            onChange(e.value);
                          }
                          if (e?.__isNew__ === undefined) {
                            register('id');
                            setValue('id', Number(e.value));
                          }
                        }}
                        controlShouldRenderValue
                        required
                        error={Boolean(errors?.professionalName)}
                        message={errors?.professionalName?.message}
                        label="Profissional"
                        placeholder="Escolha o profissional"
                        styles={selectStyles()}
                        isValidNewOption={() => true}
                        noOptionsMessage={() => 'Nenhum resultado encontrado'}
                      />
                    )}
                  />
                  {errors?.id && <p>{errors?.id?.message}</p>}
                  <InlineForms>
                    <Controller
                      name="state"
                      control={control}
                      render={({ value, onChange }) => (
                        <SearchSelect
                          {...value}
                          components={{
                            Control,
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                            Option,
                          }}
                          className="select"
                          controlShouldRenderValue
                          options={generateStateSelectOptions()}
                          onChange={(e: { value: string }) => {
                            if (!e) return;
                            onChange(e.value);
                          }}
                          defaultValue={watch('state')}
                          isDisabled
                          placeholder="Selecione"
                          formatCreateLabel={(label: string) => ({
                            label: `Buscar por ${label}`,
                          })}
                          value={stateMultiselectSelected}
                          styles={selectStyles()}
                          isValidNewOption={() => false}
                          required
                          label="Estado"
                          noOptionsMessage={() => 'Nenhum resultado encontrado'}
                          width="30%"
                        />
                      )}
                    />

                    <Controller
                      name="city"
                      control={control}
                      render={({ value, onChange }) => (
                        <SearchSelect
                          {...value}
                          className="select"
                          components={{
                            Control,
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                            Option,
                          }}
                          onChange={(e: { value: string; label: string }) => {
                            if (!e) return;
                            onChange(String(e.value));
                            setValue('healthUnit', '');
                          }}
                          options={generateCitySelectOptions()}
                          styles={selectStyles()}
                          controlShouldRenderValue
                          required
                          label="Município"
                          placeholder="Escolha o município"
                          width="70%"
                          error={Boolean(errors?.city)}
                          message={errors?.city?.message}
                          formatCreateLabel={(label: string) =>
                            `Buscar por ${label}`
                          }
                          isValidNewOption={() => false}
                          noOptionsMessage={() => 'Nenhum resultado encontrado'}
                        />
                      )}
                    />
                  </InlineForms>
                  <div style={{ marginTop: 24 }}>
                    <Controller
                      control={control}
                      name="healthUnit"
                      render={({ value, onChange }) => (
                        <SearchSelect
                          {...value}
                          className="select"
                          components={{
                            Control,
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                            Option,
                          }}
                          options={generateHealthUnitSelectOptions()}
                          onChange={(e: { value: string; label: string }) => {
                            onChange(String(e.value));
                          }}
                          value={healthUnitMultiselectSelected}
                          controlShouldRenderValue
                          required
                          label="Unidade de saúde"
                          placeholder="Escolha a unidade de saúde"
                          styles={selectStyles()}
                          formatCreateLabel={(label: string) =>
                            `Buscar por ${label}`
                          }
                          error={Boolean(errors?.healthUnit)}
                          message={errors?.healthUnit?.message}
                          isValidNewOption={() => false}
                          noOptionsMessage={() => 'Nenhum resultado encontrado'}
                        />
                      )}
                    />
                  </div>
                </Form>
              </SectionRight>
            </Main>
            <Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  resetForm();
                  actionCancel?.();
                }}
              >
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                Criar consulta
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
